import styles from "./ThemeSwitch.module.scss";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { FaSun, FaMoon } from "react-icons/fa";
import { motion } from "framer-motion";

export const ThemeSwitch = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const iconVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      rotate: 360,
      transition: {
        duration: 0.6,
        ease: "easeInOut",
      },
    },
    whileTap: { scale: 0.95, rotate: 15 },
  };

  return (
    <motion.div
      variants={iconVariants}
      initial="initial"
      animate="animate"
      whileTap="whileTap"
      className={styles.container}
    >
      {theme === "light" ? (
        <FaMoon size={20} onClick={toggleTheme} />
      ) : (
        <FaSun size={20} onClick={toggleTheme} />
      )}
    </motion.div>
  );
};

export default ThemeSwitch;
