import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, useLocation } from "react-router-dom";
import App from "./App";
import { ThemeProvider } from "./context/ThemeContext";
import "./styles/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [location.pathname]);
  return children;
};

root.render(
  <Router>
    <Wrapper>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Wrapper>
  </Router>
);
