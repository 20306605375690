import todoImage from "../../assets/images/todo.webp";
import todoImage2 from "../../assets/images/todo2.webp";
import todoImage3 from "../../assets/images/todo3.webp";
import todoBanner from "../../assets/images/todoMobile.webp";

import robotImage from "../../assets/images/robot.webp";
import robotImage2 from "../../assets/images/robot2.webp";
import robotImage3 from "../../assets/images/robot3.webp";
import robotBanner from "../../assets/images/robotMobile.webp";

import phonebookImage from "../../assets/images/phonebook.webp";
import phonebookImage2 from "../../assets/images/phonebook2.webp";
import phonebookImage3 from "../../assets/images/phonebook3.webp";
import phonebookBanner from "../../assets/images/phonebookMobile.webp";

export const projectData = [
  {
    id: "todo",
    title: "ToDo-List",
    subtitle: "todo list app easy to use and simple",
    category: "Frontend",
    image: todoImage,
    banner: todoBanner,
    image2: todoImage2,
    image3: todoImage3,
    year: "2021",
    goal: "one of my first projects in web development, I wanted to learn more about it and how it works and what it can do",
    client: " me",
    tools: "HTML, CSS, JavaScript",
    description:
      "A todo list web app that allows users to save Your daily tasks in order not to miss anything and to keep track of your tasks.",
    why: "To-do lists offer a way to increase productivity, stopping you from forgetting things, helps prioritise tasks, manage tasks effectively, use time wisely and improve time management as well as workflow.",
    liveProject: "https://aurgdev.github.io/todo-list/",
    sourceCode: "https://github.com/aurgdev/todo-list",
  },
  {
    id: "robots",
    title: "Robot friends",
    subtitle: "a app that fetches data from the internet",
    category: "Frontend",
    image: robotImage,
    banner: robotBanner,
    image2: robotImage2,
    image3: robotImage3,
    year: "2021",
    goal: "one of my second projects in web development, I wanted to learn react and how it works and what it can do",
    client: " just me",
    tools: "HTML, CSS, JavaScript, React, tachyons",
    description:
      "A web app that fetches data from the internet and displays it as a list of robots with their names, photos and other information.",
    why: "one of the most important skills in web development is to be able to use the internet and fetch data from the internet.",
    liveProject: "https://aurgdev.github.io/robofriends/",
    sourceCode: "https://github.com/aurgdev/robofriends",
  },
  {
    id: "phonebook",
    title: "Phonebook",
    subtitle: "a app that gets data from the backend and displays it",
    category: "Backend",
    description:
      "A web app that allows users to save their contacts and display them in a list.",
    image: phonebookImage,
    banner: phonebookBanner,
    image2: phonebookImage2,
    image3: phonebookImage3,
    year: "2022",
    goal: "this app is about learning how to use the backend CRUD operations",
    client: "yea me",
    tools: "HTML, CSS, JavaScript, Node.js, Express, MongoDB, Mongoose, heroku",
    why: "backend is a very important part of web development, it allows you to store data and retrieve it later.",
    liveProject: "https://peaceful-shelf-36879.herokuapp.com/",
    sourceCode: "https://github.com/aurgdev/phonebook-backend/tree/master",
  },
];
