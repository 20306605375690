import styles from "./MainText.module.scss";
import { LinkTo } from "..";
import { motion } from "framer-motion";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";

const content = {
  animate: {
    transition: { staggerChildren: 0.1 },
  },
};

const titleVariations = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

const subtitle = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

export const MainText = ({
  mainText,
  text,
  text1,
  text2,
  title,
  link,
  src,
  alt,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <div className={`${styles.root} ${styles[theme.theme]}`}>
      <div className="container">
        <div className={styles.contentContainer}>
          <div>
            <motion.div
              className={styles.container}
              variants={content}
              animate="animate"
              initial="initial"
            >
              <motion.p variants={titleVariations}>{mainText}</motion.p>
              <motion.div variants={subtitle}>{text}</motion.div>
              <motion.div variants={subtitle}>{text1}</motion.div>
              <motion.div variants={subtitle}>{text2}</motion.div>
              <motion.div variants={subtitle}>
                <LinkTo title={title} link={link} />
              </motion.div>
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              ease: "backInOut",
              duration: 1,
              delay: 0.3,
            }}
            className={styles.imageContainer}
          >
            <motion.img
              src={src}
              alt={alt}
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 0.5, y: 0 }}
              transition={{
                ease: "backInOut",
                duration: 1,
                delay: 0.3,
              }}
              style={
                theme.theme === "light"
                  ? {
                      background: "linear-gradient(to right, #fff, #000)",
                    }
                  : {
                      background: "linear-gradient(to right, #000, #fff)",
                    }
              }
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default MainText;

// export default MainText;
