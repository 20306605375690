import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import styles from "./NavToLink.module.scss";

export const NavToLink = ({ link, title, onClick }) => {
  const theme = useContext(ThemeContext);
  let activeStyle = {
    borderBottom: "2px solid currentColor",
  };
  return (
    <NavLink
      to={link}
      onClick={onClick}
      className={`${styles.link} ${styles[theme.theme]}`}
      style={({ isActive }) => (isActive ? activeStyle : undefined)}
    >
      {title}
    </NavLink>
  );
};
export default NavToLink;
