import { useState } from "react";
import { Navbar, Sidebar } from "..";

export const Header = () => {
  const [sidebar, setSidebar] = useState(false);

  const closeSidebar = () => {
    setSidebar(false);
  };

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} isOpen={sidebar} />
      <Sidebar isOpen={sidebar} closeSidebar={closeSidebar} />
    </>
  );
};
export default Header;
