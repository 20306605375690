import styles from "./Footer.module.scss";
import { NavToLink, LinkTo, Logo } from "..";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const socialLinks = [
  {
    name: "Github",
    url: "https://github.com/aurgdev",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/aurg_dev",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/mohamed_elargoubi/",
  },
  {
    name: "Facebook",
    url: "https://www.facebook.com/Mohamed7massawi/",
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/in/mohamed-elargoubi-a5b592222/",
  },
];

const navLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Works",
    path: "/works",
  },
  {
    title: "Contact",
    path: "/contact",
  },

  {
    title: "Privacy Policy",
    path: "/privacy-policy",
  },
];

export const Footer = () => {
  const theme = useContext(ThemeContext);
  const location = useLocation();
  return (
    <motion.footer
      initial={{ opacity: 0, y: 180 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        ease: "easeInOut",
        duration: 1,
      }}
      className={`${styles.footer} ${styles[theme.theme]} `}
    >
      <div className="container">
        <div className={styles.container}>
          <div className={styles.footer_item}>
            <h3>
              {location.pathname === "/contact"
                ? "More on Social"
                : "Have a project?"}
            </h3>
            <LinkTo
              title={
                location.pathname === "/contact"
                  ? "Back to Home"
                  : "LET'S MAKE SOMETHING TOGETHER!"
              }
              link={location.pathname === "/contact" ? "/" : "/contact"}
            />
          </div>
          <div className={styles.footer_item}>
            <div className={styles.split}>
              <div className={styles.social}>
                <h4>social</h4>
                <ul>
                  {socialLinks.map((link) => (
                    <li key={link.name}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.social_link}
                      >
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.navigation}>
                <h4>navigation</h4>
                <ul>
                  {navLinks.map((link) => (
                    <li key={link.title}>
                      <NavToLink title={link.title} link={link.path} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.footer_item}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.data}>
              © {new Date().getFullYear()} Mohamed El Argoubi
            </div>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};
export default Footer;
