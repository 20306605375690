import styles from "./ToggleMenu.module.scss";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { motion } from "framer-motion";

export const ToggleMenu = ({ toggleSidebar, isOpen }) => {
  const iconVariants = {
    initial: { opacity: 0, rotateZ: -90 },
    animate: {
      opacity: 1,
      rotateZ: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: 1,
      },
    },
    whileTap: { scale: 1.05, rotateZ: 45 },
  };
  return (
    <motion.div
      variants={iconVariants}
      initial="initial"
      animate="animate"
      whileTap="whileTap"
      className={styles.container}
    >
      {isOpen ? (
        <AiOutlineClose size={24} onClick={toggleSidebar} />
      ) : (
        <AiOutlineMenu size={24} onClick={toggleSidebar} />
      )}
    </motion.div>
  );
};
export default ToggleMenu;
