import React, { useEffect, useState } from "react";

export const ThemeContext = React.createContext({
  theme: "light",
  undefined,
});

export const ThemeProvider = ({ children }) => {
  const [storageTheme, setStorageTheme] = useState(
    localStorage.getItem("theme") || "dark"
  );
  const [theme, setTheme] = useState(storageTheme || "dark");

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      setStorageTheme("dark");
    } else {
      setTheme("light");
      setStorageTheme("light");
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
