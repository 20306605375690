import { useContext } from "react";
import { Logo, NavToLink, ThemeSwitch, ToggleMenu } from "..";
import { ThemeContext } from "../../context/ThemeContext";
import styles from "./Navbar.module.scss";
import { motion } from "framer-motion";

const navLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Works",
    path: "/works",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];

export const Navbar = ({ toggleSidebar, isOpen }) => {
  const theme = useContext(ThemeContext);

  return (
    <motion.header
      initial={{ opacity: 0, y: -180 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        ease: "easeInOut",
        duration: 1,
      }}
      className={`${styles.header} ${styles[theme.theme]}`}
    >
      <div className="container">
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <nav className={styles.nav}>
            <ul className={styles.navlinks}>
              {navLinks.map((link) => (
                <li key={link.title}>
                  <NavToLink link={link.path} title={link.title} />
                </li>
              ))}
            </ul>
            <div className={styles.themeSwitch}>
              <ThemeSwitch />
            </div>
            <div className={styles.toggleMenu}>
              <ToggleMenu toggleSidebar={toggleSidebar} isOpen={isOpen} />
            </div>
          </nav>
        </div>
      </div>
    </motion.header>
  );
};
export default Navbar;
