import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import styles from "./Work.module.scss";
import { motion } from "framer-motion";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";

const pageTransition = {
  initial: {
    opacity: 0,
  },

  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Work = ({ project }) => {
  const theme = useContext(ThemeContext);
  return (
    <ParallaxProvider>
      <motion.div
        variants={pageTransition}
        initial="initial"
        animate="animate"
        exit="exit"
        className={`${styles.root} ${styles[theme.theme]}`}
      >
        <div className={styles.imageContainer}>
          <ParallaxBanner
            layers={[
              {
                image: project.image,
                speed: -10,
              },
            ]}
            className={styles.image}
          />
        </div>
        <div className="container">
          <div className={styles.content}>
            <div className={styles.title}>
              <h1>{project.title}</h1>
              <p>{project.description}</p>
            </div>
            <div className={styles.year}>
              <h3>year</h3>
              <h4>{project.year}</h4>
            </div>
            <div className={styles.source}>
              <h3>source code</h3>
              <a
                href={project.sourceCode}
                target="_blank"
                rel="noopener noreferrer"
              >
                {project.sourceCode}
              </a>
            </div>
            <div className={styles.liveProject}>
              <h3>live project</h3>
              <a
                href={project.liveProject}
                target="_blank"
                rel="noopener noreferrer"
              >
                {project.liveProject}
              </a>
            </div>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <ParallaxBanner
            layers={[{ image: project.image2, speed: -15 }]}
            className={styles.image}
          />
        </div>
        <div className="container">
          <div className={styles.main}>
            <div className={styles.description}>
              <h3>{project.subtitle}</h3>
              <p>{project.why}</p>
            </div>
            <div className={styles.category}>
              <h3>category</h3>
              <p>{project.category}</p>
            </div>
            <div className={styles.client}>
              <h3>client</h3>
              <p>{project.client}</p>
            </div>
            <div className={styles.goal}>
              <h3>goal</h3>
              <p>{project.goal}</p>
            </div>
            <div className={styles.tools}>
              <h3>tools</h3>
              <p>{project.tools}</p>
            </div>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <ParallaxBanner
            layers={[{ image: project.image3, speed: -10 }]}
            className={styles.image}
          />
        </div>
      </motion.div>
    </ParallaxProvider>
  );
};

export default Work;
