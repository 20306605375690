import { Link } from "react-router-dom";
import styles from "./LinkTo.module.scss";
import { FaArrowRight } from "react-icons/fa";

export const LinkTo = ({ title, link }) => {
  return (
    <div className={styles.container}>
      {title && (
        <>
          <Link to={link} className={styles.link}>
            {title}
          </Link>
          <FaArrowRight size={18} style={{ marginTop: "15px" }} />
        </>
      )}
    </div>
  );
};
export default LinkTo;
