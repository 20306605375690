import { useContext, useEffect } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Logo, NavToLink, ThemeSwitch } from "..";
import styles from "./Sidebar.module.scss";

const navLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Works",
    path: "/works",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];

export const Sidebar = ({ isOpen, closeSidebar }) => {
  const theme = useContext(ThemeContext);
  const style = isOpen ? styles.open : styles.close;
  useEffect(() => {}, [isOpen]);
  return (
    <>
      {isOpen ? (
        <div
          className={`${styles.sidebarBackground} ${styles[theme.theme]}`}
          onClick={closeSidebar}
          onKeyPress={closeSidebar}
          role="button"
          tabIndex="0"
        ></div>
      ) : (
        <></>
      )}

      <nav className={`${style} ${styles.sidebar} ${styles[theme.theme]}`}>
        <ul className={styles.navlinks}>
          <div className={styles.logo}>
            <Logo />
          </div>
          {navLinks.map((link) => (
            <li key={link.title}>
              <NavToLink
                link={link.path}
                title={link.title}
                onClick={closeSidebar}
              />
            </li>
          ))}
          <div className={styles.themeSwitch}>
            <ThemeSwitch />
          </div>
        </ul>
      </nav>
    </>
  );
};
export default Sidebar;
