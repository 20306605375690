import React, { lazy, Suspense } from "react";
import { AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { Route, Routes, useLocation, useMatch } from "react-router-dom";
import { Footer, Header } from "./components";
import { ThemeContext } from "./context/ThemeContext";
import { projectData } from "./pages/Works/data";
import Work from "./pages/Works/Work";

const Home = lazy(() => import("./pages/Home/Home"));
const About = lazy(() => import("./pages/About/About"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const Works = lazy(() => import("./pages/Works/Works"));

function App() {
  const theme = useContext(ThemeContext);

  const location = useLocation();

  const match = useMatch("/works/:id");
  const project = match
    ? projectData.find((n) => n.id === match.params.id)
    : null;

  return (
    <div
      style={
        theme.theme === "light"
          ? { backgroundColor: "#fff" }
          : {
              backgroundColor: "#000",
              height: "100vh",
            }
      }
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route path="/*" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="works/*" element={<Works />} />
            <Route path="/works/:id" element={<Work project={project} />} />
          </Routes>
        </AnimatePresence>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
