import React, { useContext, useState } from "react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { ThemeContext } from "../../context/ThemeContext";
import styles from "./Form.module.scss";
import emailjs from "@emailjs/browser";

const MyTextInput = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </>
  );
};

export const ContactForm = () => {
  const theme = useContext(ThemeContext);
  const [buttonState, setButtonState] = useState("submit");

  return (
    <div className={styles[theme.theme]}>
      <div className="container">
        <Formik
          initialValues={{
            from_name: "", //user name
            to_name: process.env.REACT_APP_ADMIN_EMAIL, //email id of the admin
            subject: "", // subject of email
            reply_to: "", // user email
            message: "", // message of email
          }}
          validationSchema={Yup.object({
            from_name: Yup.string()
              .min(2, "Must be 15 characters or more")
              .max(40, "Must be 40 characters or less")
              .required("Required"),
            reply_to: Yup.string()
              .email("Invalid email address")
              .required("Required"),
            subject: Yup.string()
              .max(20, "Must be 20 characters or more")
              .required("Required"),
            message: Yup.string()
              .min(20, "Must be 20 characters or more")
              .required("Required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            try {
              emailjs
                .send(
                  process.env.REACT_APP_FORMIK_SERVICE_ID,
                  process.env.REACT_APP_FORMIK_TEMPLATE_ID,
                  values,
                  process.env.REACT_APP_FORMIK_USER_ID
                )
                .then(() => {
                  setSubmitting(false);
                  setButtonState("email sent");
                  values.from_name = "";
                  values.reply_to = "";
                  values.subject = "";
                  values.message = "";
                });
            } catch (error) {
              console.log(error);
              setTimeout(() => {
                setButtonState("email not sent");
              }, 3000);
            }
          }}
        >
          <Form className={`${styles.form} `}>
            <p>Let&apos;s talk</p>
            <h1>Hello! Let’s talk about your new project</h1>
            <div>
              <h3>Fill in the form to contact me or send an email to</h3>
              <a href="mailto:mohamed@elargoubi.com">mohamed@elargoubi.com </a>
            </div>
            <MyTextInput
              id="from_name"
              name="from_name"
              type="text"
              placeholder="What's your name?"
              style={
                theme.theme === "dark"
                  ? { backgroundColor: "black" }
                  : {
                      backgroundColor: "white",
                    }
              }
            />

            <MyTextInput
              id="reply_to"
              name="reply_to"
              type="email"
              placeholder="What's your email?"
              style={
                theme.theme === "dark"
                  ? { backgroundColor: "black" }
                  : {
                      backgroundColor: "white",
                    }
              }
            />

            <MyTextInput
              id="subject"
              name="subject"
              type="text"
              placeholder="what's the subject?"
              style={
                theme.theme === "dark"
                  ? { backgroundColor: "black" }
                  : {
                      backgroundColor: "white",
                    }
              }
            />
            <MyTextInput
              id="message"
              name="message"
              type="text"
              placeholder="Tell me about your project"
              style={
                theme.theme === "dark"
                  ? { backgroundColor: "black" }
                  : {
                      backgroundColor: "white",
                    }
              }
            />

            <button
              type="submit"
              style={
                theme.theme === "dark"
                  ? { backgroundColor: "#25262B" }
                  : { backgroundColor: " #e1e1e1 " }
              }
            >
              {buttonState}
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ContactForm;
